/* SingleBlogPost.scss */
.single-post-container {
  display: flex;
  flex-direction: column; // Stack elements vertically
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.single-post {
  width: 100%;
  max-width: 800px; // Set a max width for the post content to keep it centered and not span the entire width
  margin: 10px auto;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .post-title {
    font-size: 1.8rem;
    margin-bottom: 10px;

    @media (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  .post-meta {
    margin-bottom: 10px;

    small {
      color: #777;
    }
  }

  .post-content {
    line-height: 1.6;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }
  .image-container {
    display: flex;
    justify-content: center;
  }
  img {
    max-width: 70%;
    height: auto;
    margin-bottom: 20px;
  }

  .back-link {
    display: block;
    margin-top: 20px;
    text-decoration: none;
    color: #007bff;

    &:hover {
      text-decoration: underline;
    }
  }
}

.shareArticle {
  display: flex;
  flex-direction: column; // Stack items vertically
  align-items: center;
  width: 100%;
  max-width: 800px; // Ensure the share section aligns with the blog post width
  padding: 15px;
  margin-top: 20px; // Add space between the blog post and the share section

  .shareSocial {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .socialTitle {
      margin: 0 15px 0 0;
      font-size: 20px;

      @media (max-width: 767px) {
        margin-bottom: 15px;
        text-align: center;
      }
    }

    .socialList {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center; // Center the icons horizontally
      flex-wrap: wrap;

      li {
        margin: 5px;

        &:first-child {
          padding-left: 0;
        }

        button {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          text-decoration: none;
          background-color: #999;
          color: #fff;
          transition: .35s;

          i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: top left;
            transform: scale(1) translate(-50%, -50%);
            transition: .35s;
          }

          &:hover {
            i {
              transform: scale(1.5) translate(-50%, -50%);
            }
          }
        }

        &:nth-child(1) button {
          background-color: #135cb6;
        }

        &:nth-child(2) button {
          background-color: #135cb6;
        }

        &:nth-child(3) button {
          background-color: #111111;
        }

        &:nth-child(4) button {
          background-color: #BD081C;
        }

        &:nth-child(5) button {
          background-color: #1FB381;
        }
      }
    }
  }
}

.shareLink {
  .permalink {
    position: relative;
    border-radius: 30px;
    display: flex;
    align-items: center;

    .textLink {
      text-align: center;
      padding: 12px 60px 12px 30px;
      height: 45px;
      width: 100%;
      font-size: 16px;
      letter-spacing: .3px;
      color: #494949;
      border-radius: 25px;
      border: 1px solid #f2f2f2;
      background-color: #f2f2f2;
      outline: 0;
      appearance: none;
      transition: all .3s ease;

      @media (max-width: 767px) {
        width: 100%;
        padding: 12px 15px;
      }

      &:focus {
        border-color: #d8d8d8;
      }

      &::selection {
        color: #fff;
        background-color: #ff0a4b;
      }
    }

    .copyLink {
      margin-left: 10px; // add some margin to separate from the input field
      cursor: pointer;
      font-size: 20px;
      color: #ff0a4b;
      z-index: 10;

      &:hover {
        &:after {
          opacity: 1;
          transform: translateY(0) translateX(-50%);
        }
      }

      &:after {
        content: attr(title);
        width: 140px;
        bottom: -40px;
        left: 50%;
        padding: 5px;
        border-radius: 4px;
        font-size: 0.8rem;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        background-color: #000000;
        color: #ffffff;
        transform: translateY(-10px) translateX(-50%);
        transition: all 300ms ease;
        text-align: center;
      }
    }
  }
}

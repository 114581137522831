@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:900');

// Header
.button {
  background-color: var(--border-color);
}
.bio,
.contact,
.home,
.portfolio,
.blog-page-button {
  transition: 0.5s;
  font-family: 'Trispace', sans-serif;
  font-weight: 500;
  font-size: 120%;
  color: var(--text-color);
  &:hover,
  &:active,
  &.active,
  &:focus {
    transition: 0.5s;
    color: var(--text-hover-color);
  }
}

.Nav {
  color: var(--text-color);
  background-color: #0e0b1683;
  display: flex;

}



h1 {
  -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 4s; /* Firefox < 16 */
  -ms-animation: fadein 4s; /* Internet Explorer */
  -o-animation: fadein 4s; /* Opera < 12.1 */
  animation: fadein 4s;
  margin: 0;
  text-align: center;
  font-weight: 500;

  font-size: 3.4rem;
  line-height: 6.8rem;

  color: var(--text-color);

  & span {
    position: relative;
    color: var(--text-color);
  }
  span::before {
    position: absolute;
    content: '';
    height: 0.2rem;
    width: 95%;
    display: block;
    bottom: 0;
    right: 0;
    border-radius: 0.4rem 0 0.4rem 0;
    background-image: var(--gradient-rainbow);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

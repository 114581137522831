.title-home {
  display: block;
  font-size: 6rem;
  color: var(--title-color);
  font-family: "La Belle Aurore", cursive;
  font-weight: 400;
  font-style: normal;
}


.wrapper-home {
  box-sizing: block;
  margin: auto;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .wrapper {
    font-size: 1.4rem;
  }
  .title {
    font-size: 4rem;
  }
}

@media (max-width: 480px) {
  .wrapper {
    font-size: 1rem;
  }
}
@media (max-width: 280px) {
  .wrapper {
    font-size: 0.7rem;
  }
}



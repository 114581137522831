@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:900');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

body {
  font-family: 'Lato', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

h1,
h2,
h3 {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 40px;
  font-size: 50px;
}

.custom-button {
  background-color: var(--button-color);
  color: var(--text-color);
  border: none;
  transition: background-color 0.2s;
}

.card-title {
  text-align: center;
}
.custom-modal {
  max-width: 800px;
  margin: 1.75rem auto;
}
.custom-button:hover {
  background-color: var(--hover-color);
}

.card {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--text-color);
}
.modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
  
}

.card-footer {
  background-color: inherit;
  border-top: none;
}
.img-div {
  text-align: center;
}
.img-thumbnail {
  margin-top: 20px;
  max-width: 100%;
  height: auto;
  
}

.skillsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.iconHolder {
  padding-left: 0px;
  display: flex;
  gap: 40px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  justify-content: center;
}

.skillsList {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  background-color: var(--dot-color);
  transition: 0.5s;
  color: var(--text-color);
  font-size: 140%;
  text-align: center;
  font-family: 'Lato', sans-serif;
  &:hover {
    transition: 0.5s;
    color: var(--hover-color);
    background-color: var(--dot-bg-color);
  }
}

i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.skill {
  margin-top: 3px;
  font-weight: 500;
  font-family: 'Lato', sans-serif;
  font-size: 0.8rem;
}

/* Media query for mobile devices */
@media (max-width: 390px) {
  .skillsContainer {
    margin-top: 30px;
  }
  .iconHolder {
    gap: 20px;
  }
  .skillsList {
    min-width: 78px;
    min-height: 78px;
    font-size: 100%;
  }
  .skill {
    font-size: 0.6rem;
  }
}
@media (min-width: 391px) {
  .iconHolder {
    flex-direction: row;
    justify-content: center;
  }
}

$thick: 3px;
$pad: 3px;
$extra: 3px;
$color: var(--hover-color);

.modal-content-url {
  background: var(--background-color);
  text-align: center;
  a {
    color: var(--text-color);
    padding: $pad $extra;
    display: inline-block;
    border: $thick solid transparent;
    text-decoration: none;
    &:hover {
      transition: 0.5s;
      color: var(--text-wheel-color);
    }
    position: relative;
    font-size: 1.5em;
    cursor: pointer;
    letter-spacing: 0.07em;

    .text {
      font-family: 'Lato', sans-serif;
      transform: translate3d(0, $pad, 0);
      display: block;
      transition: transform 0.4s cubic-bezier(0.2, 0, 0, 1) 0.4s;
    }

    &:after {
      position: absolute;
      content: '';
      bottom: -$thick;
      left: $extra;
      right: $extra;
      height: $thick;
      background: $color;
      
      transition: transform 0.8s cubic-bezier(1, 0, 0.37, 1) 0.2s,
        right 0.2s cubic-bezier(0.04, 0.48, 0, 1) 0.6s,
        left 0.4s cubic-bezier(0.04, 0.48, 0, 1) 0.6s;
      transform-origin: left;
    }
  }

  .line {
    position: absolute;
    background: $color;

    &.-right,
    &.-left {
      width: $thick;
      bottom: -$thick;
      top: -$thick;
      transform: scale3d(1, 0, 1);
    }

    &.-top,
    &.-bottom {
      height: $thick;
      left: -$thick;
      right: -$thick;
      transform: scale3d(0, 1, 1);
    }

    &.-right {
      right: -$thick;
      transition: transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.23s;
      transform-origin: top;
    }

    &.-top {
      top: -$thick;
      transition: transform 0.08s linear 0.43s;
      transform-origin: left;
    }

    &.-left {
      left: -$thick;
      transition: transform 0.08s linear 0.51s;
      transform-origin: bottom;
    }

    &.-bottom {
      bottom: -$thick;
      transition: transform 0.3s cubic-bezier(1, 0, 0.65, 1.01);
      transform-origin: right;
    }
  }

  a:hover,
  a:active {
    .text {
      transform: translate3d(0, 0, 0);
      transition: transform 0.6s cubic-bezier(0.2, 0, 0, 1) 0.4s;
    }

    &:after {
      transform: scale3d(0, 1, 1);
      right: -$thick;
      left: -$thick;
      transform-origin: right;
      transition: transform 0.2s cubic-bezier(1, 0, 0.65, 1.01) 0.17s,
        right 0.2s cubic-bezier(1, 0, 0.65, 1.01), left 0s 0.3s;
    }

    .line {
      transform: scale3d(1, 1, 1);

      &.-right {
        transition: transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.2s;
        transform-origin: bottom;
      }

      &.-top {
        transition: transform 0.08s linear 0.4s;
        transform-origin: right;
      }

      &.-left {
        transition: transform 0.08s linear 0.48s;
        transform-origin: top;
      }

      &.-bottom {
        transition: transform 0.5s cubic-bezier(0, 0.53, 0.29, 1) 0.56s;
        transform-origin: left;
      }
    }
  }
}

.bioPage {
  
    border-radius: none;
    background-color: var(--background-color);
    color: var(--text-color);
    .bioTitle {
      margin-bottom: 1rem;
    }
    .bioText {
      font-size: 1rem;
    }
  }
  #photography {
    display: flex;
    justify-content: center;
  }
 
  .scroll-grid-container {
    overflow: hidden;
    background-color: var(--scroll-grid-bg-color);
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 2rem;
    width: 75%;
  }
  
  .photo-grid {
    height: 330px;
    background-size: 1600px;
    background-repeat: repeat-x;
    background-position: center;
    width: 4800px;
    -webkit-animation: scroll-grid 120s linear infinite;
    animation: scroll-grid 120s linear infinite;
  }
  
  @keyframes scroll-grid {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-2400px);
    }
  }
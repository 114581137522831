/* blog.scss */
.Title {
  text-align: center;
  padding: 20px;
}

.blogTitle {
  font-size: 2rem;
}

.blog h3 {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.blog {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
}

.post {
  background: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 3px solid var(--border-color);
  margin: 10px;
  overflow: hidden;
  width: calc(100% - 20px);
  max-width: 400px;
}

.post img {
  width: 100%;
  height: auto;

}

.post div {
  padding: 15px;
}

.post h2 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.post small {
  color: #666;
}

.post p {
  font-size: 1rem;
  margin: 10px 0;
}

.no-posts-message {
  text-align: center;
  margin: 20px;
  font-size: 1.5rem;
  color: var(--text-color)
}

/* Media Queries for Responsiveness */
@media (min-width: 768px) {
  .blog {
    justify-content: flex-start;
  }

  .post {
    width: calc(50% - 20px);
  }
}

@media (min-width: 1024px) {
  .post {
    width: calc(33.333% - 20px);
  }
}
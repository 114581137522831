.footer {
  margin-top: auto;
  background-color: transparent;
}
.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
}
.sourcelink {
    color: var(--text-color);
    &:hover {
      color: var(--hover-color);
    }
}

// Colors
:root {
  --background-color: #0e0b16;
  --background-color-dark: #034aa6;
  --scroll-grid-bg-color: #24252a;
  --nav-bg-color: #034aa6;
  --text-color: #f2f2f2;
  --text-wheel-color: #89b3d9;
  --text-hover-color: #d9d8d8b8;
  --button-color: #034aa6;
  --dot-color: #034aa6;
  --dot-bg-color: rgba(162, 57, 202, 0.1);
  --hover-color: #035aa6;
  --scrollbar-track-color: #034aa6;
  --scrollbar-thumb-color: #e7dfddb9;
  --scrollbar-thumb-hover-color: #e7dfdd;
  --border-color: #f2f2f2;
  --border-color-dark: #034aa6;
  --gradient-rainbow: linear-gradient(
    115deg,
    #f2f2f2,
    #035aa6,
    #03178c,
    #f2f2f2
  ) !important;
  --color-dark: #131417;
  --color-white: #f2f2f2;
  --home-page-text: #4d4d4d;
  --title-color: #b8b8b8;
}
// Breakpoints
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
// Fade In Animation
.animation {
  -webkit-animation: fadein 2s; // Safari, Chrome and Opera > 12.1
  -moz-animation: fadein 2s; // Firefox < 16
  -ms-animation: fadein 2s; // Internet Explorer
  -o-animation: fadein 2s; // Opera < 12.1
  animation: fadein 2s;
}
// Scrollbar
::-webkit-scrollbar {
  width: 10px;
}
// Track
::-webkit-scrollbar-track {
  background-color: var(--background-color-dark);
}
// Handle
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}
// Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-color);
}

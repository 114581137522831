.wrapper {
 
  
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: var(--text-color);

  @media (max-width: 700px) {
    padding: 24px;
  }
}


.grid {
  width: 100%;
  max-width: 40%;
  
  @media (max-width: 700px) {
    max-width: 100%;
  }
}
.form {
  background-color: var(--color-white);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 700px) {
    padding: 24px;
  }

  .input {
    background-color: var(--color-white);
    border: 1px solid var(--border-color);
    color: var(--color-dark);

    &::placeholder {
      color: var(--scrollbar-thumb-color);
    }
  }

  .inputLabel {
    color: var(--color-dark);
  }

  .control {
    background-color: var(--button-color);
    color: var(--color-white);
    
    &:hover {
      background-color: var(--hover-color);
    }
  }
}
.btngroup {
  display: flex;
  justify-content: center;
}
.social-icons {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 50px;

  .socialIcon {
    color: var(--text-color);

    &:hover {
      color: var(--hover-color);
    }
  }
}
.thank-you-page {
  display: block;
  
  text-align: center;
}
